<template>
  <section
    class="policy-list"
    :class="{'policy-selection':all_policy_type==6&&type==1}">
    <div v-if="type==1">
      <div class="flex-sb-center header-title">
        <h1
          class="">
          All Policy
        </h1>
        <div>
          <v-button
            class="button-theme mg-r-8"
            @click="resetFilter">Clear</v-button>
          <v-button
            :disabled="canExport"
            @click="handleExport">Export</v-button>
        </div>
      </div>
      <!--      <h1-->
      <!--        v-if="type==7"-->
      <!--        class="header-title">-->
      <!--        Client- Pending Refund-->
      <!--      </h1>-->
      <div
      >
        <el-tabs
          v-model="all_policy_type"
          @tab-click="handleClick">
          <el-tab-pane
            v-for="(item,index) in allPolicyType"
            :key = index
            :label="item.name"
            :name="item.id"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div   v-if="type==2">
      <div class="flex-sb-center header-title">
        <h1
          class="">
          All Quotation
        </h1>
        <div class="right">
          <v-button
            class="button-theme"
            @click="resetFilter">Clear</v-button>
        </div>
      </div>
    </div>
    <div   v-if="type==3">
      <div class="flex-sb-center header-title">
        <h1
          class="">
          Upcoming Renew
        </h1>
        <div>
          <v-button
            class="button-theme mg-r-8"
            @click="resetFilter">Clear</v-button>
          <v-button
            :disabled="canExport"
            @click="handleExport">Export</v-button>
        </div>
      </div>
      <div
      >
        <el-tabs
          v-model="typeName"
          @tab-click="handleClick">
          <el-tab-pane
            v-for="(item,index) in upComingList"
            :key = index
            :label="item.name"
            :name="item.id"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div   v-if="type==4">
      <!--      <h1-->
      <!--        class="header-title">-->
      <!--        Pending Renew Quote-->
      <!--      </h1>-->
      <div class="flex-sb-center header-title">
        <h1
          class="">
          Pending Renew Quote
        </h1>
        <div>
          <v-button
            class="button-theme mg-r-8"
            @click="resetFilter">Clear</v-button>
          <v-button
            :disabled="canExport"
            @click="handleExport">Export</v-button>
        </div>
      </div>
      <div
      >
        <el-tabs
          v-model="typeName"
          @tab-click="handleClick">
          <el-tab-pane
            v-for="(item,index) in pendingList"
            :key = index
            :label="item.name"
            :name="item.id"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div   v-if="type==8">
      <!--      <h1-->
      <!--        class="header-title">-->
      <!--        Pending Renew Quote-->
      <!--      </h1>-->
      <div class="flex-sb-center header-title">
        <h1
          class="">
          iPad Ticket
        </h1>
        <!--        <div>-->
        <!--          <v-button @click="handleExport">Export</v-button>-->
        <!--        </div>-->
      </div>
      <div
      >
        <el-tabs
          v-model="status"
          @tab-click="handleClick">
          <el-tab-pane
            v-for="(item,index) in ticketList"
            :key = index
            :label="item.name"
            :name="item.id"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="show-table-maxscreen">
      <div
        v-if="isSelect"
        class="flex-justify flex flex-content">
        <div
          class="flex title-tip">
          <div class="flex-cloumn">
            <span> All Currently Listed </span>
            <span></span>
          </div>
          <div class="flex-cloumn">
            <span>Total Policies</span>
            <span class="number">{{multipleSelection.length}}</span>
          </div>
          <!--          <div-->
          <!--            class="title-tip">-->
          <!--            <span>{{multipleSelection.length}} Selected</span>-->
          <!--          </div>-->
        </div>

        <div class="flex-cloumn flex-button">
          <div class="flex">
            <v-button
              class="button-theme"
              :disabled="multipleSelection.length<=0"
              @click="handlerMark"
            >Mark As Settled</v-button>
          </div>
        </div>
      </div>
      <el-table
        ref="table"
        :header-row-class-name="'table-header'"
        :data="tableData"
        style="width: 100%"
        :row-key="getRowId"
        @row-click="rowClick"
        @selection-change="handleSelectionChange"
      >
        <!--        <el-table-column-->
        <!--          v-if="showIssue"-->
        <!--          prop="issue_date"-->
        <!--          :show-overflow-tooltip="true"-->
        <!--          width="180">-->
        <!--          <template slot="header">-->
        <!--            <div  class="table-slot-header">-->

        <!--              <span>Issue Date</span>-->
        <!--              <v-date-picker-->
        <!--                v-model="issueDate"-->
        <!--                type="daterange"-->
        <!--                :rangeSeparator="'-'"-->
        <!--                :placeholder="'Date'"-->
        <!--                @change="handlerChange"-->
        <!--              />-->
        <!--            </div>-->
        <!--          </template>-->
        <!--          <template slot-scope="scope">-->
        <!--            <span>{{showMonth(scope.row.issue_date)}}</span>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column
          :reserve-selection='true'
          type="selection"
          :class-name="isSelect?'':'selection_none'"
          :width="isSelect?55:10">
        </el-table-column>
        <el-table-column
          v-if="showCreateDate"
          width="250">
          <template slot="header" >
            <div  class="table-slot-header">

              <span>Create Date</span>
              <v-date-picker
                v-model="createdDate"
                type="daterange"
                :rangeSeparator="'-'"
                :placeholder="'Date'"
                @change="handlerChange"
              />
            </div>
          </template>
          <template slot-scope="scope">
            <p>{{scope.row.created_date}}</p>
            <p class="liter-text gray-color fs-12">{{scope.row.created_time}}</p>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showEffective"
          prop="effective_date"
          :show-overflow-tooltip="true"
          width="250">
          <template
            slot="header"
          >
            <div  class="table-slot-header">

              <span>Effective Date</span>
              <v-date-picker
                v-model="date"
                type="daterange"
                :rangeSeparator="'-'"
                :placeholder="'Date'"
                @change="handlerChange"
              />
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{showMonth(scope.row.effective_date)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showExipredDate"
          prop="expiry_date"
          :show-overflow-tooltip="true"
          width="250">
          <template slot="header" >
            <div  class="table-slot-header">

              <span>Expiry Date</span>
              <v-date-picker
                v-model="expiryDate"
                type="daterange"
                :rangeSeparator="'-'"
                :placeholder="'Date'"
                @change="handlerChange"
              />
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{showMonth(scope.row.expiry_date)}}</span>
          </template>
        </el-table-column>

        <el-table-column
          v-if="showPeriod"
          :show-overflow-tooltip="true"
          prop="date"
          width="180">
          <template slot="header" >
            <div  class="table-slot-header">
              <span>Policy Period</span>
              <span class="block-table"></span>
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{showMonth(scope.row.effective_date)+'-'+ showMonth(scope.row.expiry_date)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showType"
          width="180">
          <template slot="header" >
            <div  class="table-slot-header">
              <span>Policy Type</span>
              <v-select
                v-model="policy_type"
                :list="policyTypeList"
                @change="handlerChange"></v-select>
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{ policyType(scope.row.policy_type) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isShowDraft"
          :show-overflow-tooltip="true"
          width="150"
        >
          <template
            slot="header"
          >
            <div  class="table-slot-header">
              <span>Created by</span>
              <v-select
                v-model="created_by"
                :list="createdByList"
                @change="handlerChange"
              ></v-select>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="flex">
              <span  class="text-over-flow">{{getOptionName('globalOptions.created_by',scope.row.created_by)}}</span>
            </div>

            <!--            <span>{{getOptionName('globalOptions.company',scope.row.company_id)}}</span>-->
          </template>
        </el-table-column>
        <el-table-column
          v-if="isShowTicket"
          :show-overflow-tooltip="true"
          width="150"
          prop="serial_number"
        >
          <template
            slot="header"
          >
            <div  class="table-slot-header">
              <span>Ticket No.</span>
              <v-input
                v-model="serial_number"
                placeholder="Search"
                @change="handlerChange"
              ></v-input>
            </div>
          </template>
          <template slot-scope="scope">
            <span  class="text-blold">{{scope.row.serial_number}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showInsurer"
          :show-overflow-tooltip="true"
          width="150"
        >
          <template
            slot="header"
          >
            <div  class="table-slot-header">
              <span>Insurer</span>
              <v-select
                v-model="company_id"
                :list="companyList"
                @change="handlerChange"
              ></v-select>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="flex flex-logo">
              <span
                v-if="showOtherInsurer(scope.row.company_id)"
                class="company-logo">
                <img
                  :src="getOptionData('insureSVGOptions',scope.row.company_id).logo"
                  alt="company" />
              </span>
              <span  class="text-over-flow">{{scope.row.company_name}}</span>

            </div>

            <!--            <span>{{getOptionName('globalOptions.company',scope.row.company_id)}}</span>-->
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="180"
          label="Product">
          <template
            slot="header"
          >
            <div  class="table-slot-header">
              <span>Product</span>
              <v-select
                v-model="product_id"
                :list="productList"
                @change="handlerChange"
              ></v-select>
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{getOptionName('productTypeOptions',scope.row.product_id)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          min-width="220px"
          :show-overflow-tooltip="true"
          prop="policy_holder"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Policyholder (VRN)</span>
              <v-input
                v-model="search_content_2"
                placeholder="Search"
                :search-type="2"
                @change="handlerSearch(2)"></v-input>
            </div>
          </template>
          <template slot-scope="scope">
            <span
              :class="{'red-color':scope.row.blacklist}"
              class="cell-wrap">{{scope.row.policy_holder}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showPolicyNo"
          min-width="220px"
          :show-overflow-tooltip="true"
          prop="policy_no"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Policy No.</span>
              <v-input
                v-model="search_content_3"
                placeholder="Search"
                :search-type="3"
                @change="handlerSearch(3)"></v-input>
            </div>
          </template>
          <template slot-scope="scope">
            <span  class="text-over-flow">{{scope.row.policy_no||'-'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showCreateDateLast"
          width="150"
        >
          <template slot="header" >
            <div  class="table-slot-header">

              <span>Create Date</span>
              <v-date-picker
                v-model="createdDate"
                type="daterange"
                :rangeSeparator="'-'"
                :placeholder="'Date'"
                @change="handlerChange"
              />
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{showMonth(scope.row.created_at)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showType||isRefund"
          :show-overflow-tooltip="true"
          prop="contact_no"
          width="120"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Contact No.</span>
              <v-input
                v-model="search_content_1"
                placeholder="Search"
                key-number
                :maxlength="8"
                :search-type="1"
                @change="handlerSearch(1)"></v-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showType&&all_policy_type!=6"
          :show-overflow-tooltip="true"
          min-width="120"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Status</span>
              <v-select
                v-model="policy_status"
                :list="policy_status_list"
                @change="handlerChange"
              ></v-select>
            </div>
          </template>
          <template slot-scope="scope">
            <span
              v-if="scope.row.policy_status&&type==1"
              class="span-options"
              :class="{
                'orange':scope.row.incomplete_tag?true:false,
                'color-info':scope.row.policy_status==30,
                'color-cancel':[40,50].includes(scope.row.policy_status),
              }"
            >{{ scope.row.incomplete_tag?'Incomplete':getOptionName('globalOptions.policy_status',scope.row.policy_status) }}</span>
            <span v-else>
              <span
                v-if="isShowDraft"
                class="span-options"
                :class="{
                  'dark':scope.row.status==2,
                  'green':scope.row.status==3,
                  'primary':scope.row.status==1,
                  'red':scope.row.status==4,
                }"
              >{{ getOptionName('globalOptions.status',scope.row.status) }}</span>
              <span v-else>{{scope.row.status==1?'Draft':'Pending Payment '}}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showTell"
          :show-overflow-tooltip="true"
          prop="tell_no"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Tell No.</span>
              <v-input
                v-model="search_content_1"
                placeholder="Search"
                key-number
                :maxlength="8"
                :search-type="1"
                @change="handlerSearch(1)"></v-input>
            </div>
          </template>
          <template slot-scope="scope">
            <span >{{scope.row.contact_no||'-'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="[1,2].includes(type)"
          :show-overflow-tooltip="true"
          width="120"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Payment</span>
            </div>
          </template>
          <template slot-scope="{row}">
            <template v-if="row.payment">
              <p>{{row.payment.amount_received|formatMoney(2,true)}}</p>
              <p class="liter-text gray-color2 fs-12">{{row.payment.type_name}}</p>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          v-if="[4].includes(type)&&typeName=='pending_quota'"
          :show-overflow-tooltip="true"
          width="152"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Payment</span>
            </div>
          </template>
          <template slot-scope="{row}">
            <template>
              <p>{{row.amount_received|formatMoney(2,true)}}</p>
              <span
                v-if="!row.paid"
                class="fs-12 span-options span-options__litter orange"
              >Pending Payment</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          v-if="[1,3,2].includes(type)"
          :show-overflow-tooltip="true"
          width="150"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>NCD</span>
            </div>
          </template>
          <template slot-scope="{row}">
            <template>
              <span
              >
                <span>{{row.ncd}}</span>
              </span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          v-if="[1,3,4].includes(type)"
          :show-overflow-tooltip="true"
          width="150"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Description</span>
            </div>
          </template>
          <template slot-scope="{row}">
            <template v-if="row.desc">
              <span
                v-for="(item,index) in row.desc"
                :key="'desc_'+index">
                <span>{{ item }} {{(index+1)==row.desc.length?'':', '}}</span>
              </span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          v-if="[1].includes(type)"
          :show-overflow-tooltip="true"
          width="200"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Remark</span>
            </div>
          </template>
          <template slot-scope="scope">
            <p class="text-over-flow ">{{scope.row.internal_remark}}</p>
          </template>
        </el-table-column>
        <el-table-column
          v-if="[3,4].includes(type)"
          :show-overflow-tooltip="true"
          width="200"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Remark</span>
            </div>
          </template>
          <template slot-scope="scope">
            <p class="text-over-flow ">{{scope.row.remark}}</p>
          </template>
        </el-table-column>
        <el-table-column
          v-if="[4].includes(type)"
          :show-overflow-tooltip="true"
          width="100"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Document</span>
            </div>
          </template>
          <template slot-scope="{row}">
            <p
              v-if="row.document_supplementary"
            >  <span class="icon-error icon-error__12">!</span></p>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showRefer&&all_policy_type!=6||[3,4].includes(type)"
          :show-overflow-tooltip="true"
          min-width="120"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Agency Code</span>
              <v-input
                v-model="search_content_4"
                placeholder="Search"
                :search-type="4"
                @change="handlerSearch(4)"></v-input>
            </div>
          </template>
          <template slot-scope="scope">
            <span
              v-if="scope.row.referrer.id"
              class="link hand flex flex-aligin"
              @click.stop="handlerReferrer(scope.row.referrer.id)">
              <font-awesome-icon icon="link"></font-awesome-icon>
              <span>{{scope.row.referrer.name}}</span>
            </span>
            <span v-else>NIL</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="show-table-minscreen">
      <div class="search-box ">
        <div
          class="search-title theme-second-blue"
          @click="show = !show">
          <div class="flex flex-center">
            <font-awesome-icon icon="filter"></font-awesome-icon>
            <span class="is-bold">Filter & Sort</span>
          </div>
          <font-awesome-icon :icon="show?'sort-up':'sort-down'"></font-awesome-icon>
        </div>
        <el-collapse-transition>
          <div
            v-show="show"
          >
            <div  class="margintop">
              <el-row>
                <v-row-col v-if="isShowDraft">
                  <span class="is-bold">Ticket No.</span>
                  <v-input
                    v-model="serial_number"
                    placeholder="Search"
                    @change="handlerChange"></v-input>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Date Range</span>
                  <div class="margin">
                    <v-select
                      v-model="selectDate"
                      :list="selectDateOptions"></v-select>
                  </div>
                  <v-date-picker
                    v-model="date"
                    type="daterange"
                    :isPicker="true"
                    @change="handlerChange"
                  />
                </v-row-col>
                <v-row-col v-if="showCreateDate||showCreateDateLast">
                  <span class="is-bold">Created Date</span>
                  <div class="margin">
                    <v-date-picker
                      v-model="createdDate"
                      type="daterange"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"
                      :isPicker="true"
                      @change="handlerChange"
                    />
                  </div>
                </v-row-col>
                <v-row-col v-if="showEffective">
                  <span class="is-bold">Effective Date</span>
                  <div class="margin">
                    <v-date-picker
                      v-model="exffectiveDate"
                      type="daterange"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"
                      :isPicker="true"
                      @change="handlerChange"
                    />
                  </div>
                </v-row-col>
                <v-row-col v-if="showExipredDate">
                  <span class="is-bold">Expiry Date</span>
                  <div class="margin">
                    <v-date-picker
                      v-model="expiryDate"
                      type="daterange"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"
                      :isPicker="true"
                      @change="handlerChange"
                    />
                  </div>
                </v-row-col>
                <v-row-col v-if="showType">
                  <span class="is-bold">Policy Type</span>
                  <v-select
                    v-model="policy_type"
                    :list="policyTypeList"
                    @change="handlerChange"></v-select>
                </v-row-col>
                <v-row-col v-if="isShowDraft">
                  <span class="is-bold">Created by</span>
                  <v-select
                    v-model="created_by"
                    :list="createdByList"
                    @change="handlerChange"></v-select>
                </v-row-col>

                <v-row-col  v-if="showInsurer">
                  <span class="is-bold">Insurer</span>
                  <v-select
                    v-model="company_id"
                    :list="companyList"
                    @change="handlerChange"
                  ></v-select>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold" >Product</span>
                  <v-select
                    v-model="product_id"
                    :list="productList"
                    @change="handlerChange"></v-select>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Policyholder (VRN)</span>
                  <v-input
                    v-model="search_content_2"
                    placeholder="Search"
                    :search-type="2"
                    @change="handlerSearch(2)"></v-input>
                </v-row-col>
                <v-row-col v-if="showPolicyNo">
                  <span class="is-bold">Policy No.</span>
                  <v-input
                    v-model="search_content_3"
                    placeholder="Search"
                    :search-type="3"
                    @change="handlerSearch(3)"></v-input>
                </v-row-col>
                <v-row-col  v-if="showType">
                  <span class="is-bold">Contact No.</span>
                  <v-input
                    v-model="search_content_1"
                    placeholder="Search"
                    :search-type="1"
                    @change="handlerSearch(1)"></v-input>
                </v-row-col>
                <v-row-col v-if="showType">
                  <span class="is-bold">Status</span>
                  <v-select
                    v-model="policy_status"
                    :list="policy_status_list"></v-select>
                </v-row-col>
                <v-row-col v-if="showTell">
                  <span class="is-bold">Tell No.</span>
                  <v-input
                    v-model="search_content_1"
                    placeholder="Search"
                    :search-type="1"
                    @change="handlerSearch(1)"></v-input>
                </v-row-col>
                <v-row-col  v-if="showRefer">
                  <span class="is-bold">Agency Code</span>
                  <v-input
                    v-model="search_content_4"
                    placeholder="Search"
                    :search-type="4"
                    @change="handlerSearch(4)"></v-input>
                </v-row-col>
              </el-row>
            </div>
          </div>
        </el-collapse-transition>
      </div>

      <div
        v-for="(item,index) in tableData"
        :key="index"
        class="table"
        @click="rowClick(item)">
        <div
          v-if="showCreateDate"
          class="tr">
          <span>Create Date</span>
          <span>{{item.created_date}} {{item.created_time}}</span>
        </div>
        <div
          v-if="showExipredDate"
          class="tr">
          <span>Expiry Date</span>
          <span>{{showMonth(item.expiry_date)}}</span>
        </div>
        <div
          v-if="showEffective"
          class="tr">
          <span>Effective Date</span>
          <span>{{showMonth(item.effective_date)}}</span>
        </div>

        <div
          v-if="showPeriod"
          class="tr">
          <span>Policy Period</span>
          <span>{{showMonth(item.effective_date)+'-'+ showMonth(item.expiry_date)}}</span>
        </div>

        <div
          v-if="showInsurer"
          class="tr">
          <span>Insurer</span>
          <span>{{item.company_name}}</span>
        </div>

        <div
          v-if="showType"
          class="tr">
          <span>Policy Type</span>
          <span>{{policyType(item.policy_type)}}</span>
        </div>
        <div
          v-if="isShowDraft"
          class="tr">
          <span>Created by</span>
          <span>{{getOptionName('globalOptions.created_by',item.created_by)}}</span>
        </div>
        <div
          v-if="isShowTicket"
          class="tr">
          <span>Ticket No.</span>
          <span>{{item.serial_number}}</span>
        </div>
        <div
          class="tr">
          <span>Product</span>
          <span>{{getOptionName('productTypeOptions',item.product_id)}}</span>
        </div>
        <div
          class="tr">
          <span>Policyholder (VRN)</span>
          <span  :class="{'red-color':item.blacklist}">{{item.policy_holder}}</span>
        </div>
        <div
          v-if="showCreateDateLast"
          class="tr">
          <span>Create Date</span>
          <span>{{showMonth(item.created_at)}}</span>
        </div>
        <div
          v-if="showPolicyNo||isRefund"
          class="tr">
          <span>Policy No.</span>
          <span>{{item.policy_no||'-'}}</span>
        </div>
        <div
          v-if="showType||isRefund"
          class="tr">
          <span>Contact No.</span>
          <span>{{item.contact_no}}</span>
        </div>
        <div
          v-if="showTell"
          class="tr">
          <span>Tell No.</span>
          <span>{{item.contact_no}}</span>
        </div>
        <div
          v-if="showType"
          class="tr">
          <span>Status</span>
          <span v-if="type==1" >{{ getOptionName(globalOptions.policy_status,item.policy_status)}}</span>
          <span v-else>{{item.status==1?'Draft':'Pending Payment '}}</span>
        </div>
        <div
          v-if="[1,2].includes(type)"
          class="tr">
          <span>Payment</span>
          <template v-if="item.payment">
            <span>
              <p>{{item.payment.amount_received|formatMoney(2,true)}}</p>
              <p class="liter-text gray-color2 fs-12">{{item.payment.type_name}}</p>
            </span>
          </template>
        </div>
        <div
          v-if="[4].includes(type)&&typeName=='pending_quota'"
          class="tr">
          <span>Payment</span>
          <template>
            <span>
              <p>{{item.amount_received|formatMoney(2,true)}}</p>
              <p class="liter-text gray-color2 fs-12">
                <span
                  v-if="!item.paid"
                  class="fs-12 span-options span-options__litter orange"
                >Pending Payment</span>
              </p>
            </span>
          </template>
        </div>
        <div
          v-if="[1,3,2].includes(type)"
          class="tr">
          <span>NCD</span>
          <template >
            <span>
              {{item.ncd}}
            </span>
          </template>
        </div>
        <div
          v-if="[1,3,4].includes(type)"
          class="tr">
          <span>Description</span>
          <template v-if="item.desc">
            <span
              v-for="(items,index) in item.desc"
              :key="'desc_'+index">
              <span>{{ items }} {{(index+1)==items.desc&&items.desc.length?'':', '}}</span>
            </span>
          </template>
        </div>
        <div
          v-if="[1].includes(type)"
          class="tr">
          <span>Remark</span>
          <span>{{item.internal_remark}}</span>
        </div>
        <div
          v-if="[3,4].includes(type)"
          class="tr">
          <span>Remark</span>
          <span>{{item.remark}}</span>
        </div>
        <div
          v-if="[4].includes(type)"
          class="tr">
          <span>Document</span>
          <span
            v-if="item.document_supplementary"
          >  <span class="icon-error icon-error__12">!</span></span>
        </div>

        <div
          v-if="type==1&&all_policy_type!=6||[3,4].includes(type)"
          class="tr">
          <span>Agency Code</span>
          <span
            v-if="item.referrer.id"
            class="link"
            @click.stop="handlerReferrer(item.referrer.id)">{{item.referrer.name}}</span>
          <span v-else>NIL</span>
        </div>
      </div>
    </div>
    <v-pagination
      :total="pageConfig.total"
      :current="pageConfig.currentPage"
      @handle-current-change="currentChange"
      @handle-size-change="handleSizeChange"
    />
    <mark-settle-payment
      v-model="showSettle"
      :amount-received="amountReceived"
      :order-list="orderList"
      @refresh="handleRefresh"></mark-settle-payment>
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
import {getPolicyList, getPolicyListExport} from '@/api/policy'
import {getTicketList} from '@/api/ticket'
import {mapActions, mapState} from 'vuex'
import {downloadFile} from '@/utils/download'
import markSettlePayment from '@/views/components/dialog/markSettlePayment'
import MixinFormat from '@/mixins/format'
import MixinFilter from '@/mixins/filter'

export default {
  name: 'list',
  components:{
    markSettlePayment
  },
  mixins: [MixinOptions,MixinFormat,MixinFilter],
  props:{
    type:{
      type:Number,
      default:1,//1 all ,2 draft  3 pending d 4 renew  5 pai 6 expired 7 refund_client , 8 ticket
    },
    filterTableKey:{
      type:String,
      default: ''
    }
  },
  data(){
    return {
      typeName:'',
      show:false,
      showSettle:false,

      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        to:'',
        per_page:1,
        size:20
      },
      tableData: [],
      multipleSelection:[],
      exffectiveDate:'',
      expiryDate:'',
      createdDate:'',
      issueDate:'',
      company_id:'',
      product_id:'',
      search_content:'',
      all_policy_type:'0',
      created_by:'',
      serial_number:'',
      policy_type:'',
      status:0,
      search_type:'',
      content:'',
      date:'',
      policy_status:'',
      selectDate:1,
      search_content_1:'',
      search_content_2:'',
      search_content_3:'',
      search_content_4:'',
      search_content_5:'',
      search_content_6:'',
      search_content_7:'',
      filterData:{
        exffectiveDate:'',
        expiryDate:'',
        createdDate:'',
        issueDate:'',
        company_id:'',
        product_id:'',
        search_content:'',
        all_policy_type:'0',
        created_by:'',
        serial_number:'',
        policy_type:'',
        status:0,
        search_type:'',
        typeName:'',
        content:'',
        date:'',
        policy_status:'',
        selectDate:1,
        search_content_1:'',
        search_content_2:'',
        search_content_3:'',
        search_content_4:'',
        search_content_5:'',
        search_content_6:'',
        search_content_7:'',
      },
    }
  },

  computed:{
    ...mapState('policy',['search']),
    isSelect(){
      return Number(this.all_policy_type) === 5
    },
    showPolicyNo(){
      return [1,3,4,7].includes(this.type)
    },
    canExport(){
      if(this.createdDate||this.date||this.expiryDate||this.policy_type||this.created_by||this.serial_number||this.company_id||this.product_id||this.search_content||this.policy_status)
        return false
      return true
    },
    showIssue(){
      return this.type==1||this.type==5||this.type==7
    },
    isShowDraft(){
      return this.type==2||this.type==8
    },
    isShowTicket(){
      return this.type==8
    },
    isRefund(){
      return this.all_policy_type==6
    },
    showEffective(){
      return this.type==1||this.type==5||this.type==7
    },
    showInsurer(){
      return [1,3,4,5,7].includes(this.type)
    },
    showRefer(){
      return this.type==1||this.type==5
    },
    showPeriod(){
      return this.type==4||this.type==6||this.type==5
    },
    showTell(){
      return this.type==5||this.type==6
    },
    showCreateDate(){
      return this.type==2
    },
    showCreateDateLast(){
      return this.type ==4
    },
    showExipredDate(){
      return this.type ==3||this.type==1
    },
    //type\contact no\status
    showType(){
      return this.type ==1||this.type ==2||this.type==5||this.type==8
    },
    policy_status_list(){
      let arr = [{name:'All',id:''}]
      if(this.isShowDraft){
        return arr.concat(this.globalOptions.status)
      }
      return arr.concat(this.globalOptions.policy_status)
    },
    companyList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.all_policy_company)
    },
    createdByList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.created_by)
    },
    productList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.product)
    },
    policyTypeList(){
      let arr = [{name:'Latest status',id:''}]
      return arr.concat(this.globalOptions.policy_type)
    },
    orderList() {
      const orderList = this.multipleSelection.reduce((result, item) => {
        result.push(item.order_no)
        return result
      }, [])
      return orderList
    },
    amountReceived() {
      let total = this.multipleSelection.reduce((result, item) => {
        result = result + item.amount_received
        return result
      }, 0)
      return total
    },
  },
  watch: {
    search: {
      deep: true,
      immediate: true,
      handler(val) {
        let {search_content, search_type} = val || {}
        this[`search_content_${search_type}`] = search_content || ''
        this.search_content = search_content || ''
        this.search_type = search_type || ''
        this.content=this[`search_content_${search_type}`]
        this.search_content=this[`search_content_${search_type}`]
        // this[`search_content_${search_type}`] = val
        // }
        // this.setSearch(params)
        let arr = [1,2,3,4,5,6,7]
        arr.filter(item=>{
          if(item!=search_type){
            this[`search_content_${item}`] =''
          }
        })
        if(this.type==1)
        {
          this.typeName ='all'
          this.getList()
          // search_content
          // this.search_type =''
        }else{
          this.search_content=''
        }
      }
    },
    type: {
      immediate: true,
      handler (val)
      {
        if (val) {
          let params = {
            1:'all',
            2:'draft',
            3:'upcoming_renew',
            5:'paid',
            4:'pending_quota',
            6:'expired',
            7:'refund_client',
          }
          if(val!==1)
            this.content = ''
          this.typeName = this.typeName?this.typeName:params[val]
          let {name} = this.$route.query
          if(name){
            this.typeName = name
          }
          if(val==8)
            this.network().getTicketList()
          else
            this.network().getPolicyList()
        }
      }

    },
    typeName: {
      immediate: true,
      handler (val)
      {
        if(!val){
          this.initTypeName()
        }
      }

    },
  },
  created() {

  },
  methods:{
    ...mapActions('policy', ['setSearch']),
    initTypeName(){
      let params = {
        1:'all',
        2:'draft',
        3:'upcoming_renew',
        5:'paid',
        4:'pending_quota',
        6:'expired',
        7:'refund_client',
      }
      this.typeName = params[this.type]
    },
    handleRefresh(){
      this.multipleSelection = []
      this.handleClick()
    },
    getRowId (row) {
      return row.order_no
    },
    handlerMark(){
      this.showSettle = true
    },
    selectable(row, index) {
      return  false
      return row.is_settled == false
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleExport(){
      this.network().getPolicyListExport()
    },
    handleClick(){
      this.pageConfig.currentPage = 1
      this.getList()
    },
    showOtherInsurer(company_id) {
      let arr =  [1,2,4,5,6,8,9,11,12,13,3]
      return arr.includes(company_id)
    },
    statusData(id){
      let status = {
        30:'Inforce ',
        40:'Cancelled',
        50:'Expired'
      }
      return status[id]
    },
    policyType(type){
      let status = {
        200:'New',
        201:'Renew',
      }
      return this.getOptionName('globalOptions.policy_type',type)
    },
    rowClick(data){
      let {policy_id,status,order_no}  =data||{}
      if(this.type==1){
        // this.setSearch(null)
      }
      this.updateFilter()
      if(this.all_policy_type==5||this.all_policy_type==6){
        this.$router.push({
          path:'/quotation/premium/info',
          query:{
            order_no:order_no,
          }
        })
      }
      else if(this.type==8){
        this.$router.push({
          path:'/ticket/info',
          query:{
            order_no:order_no,
            policy_id:policy_id,
            type:this.type,
          }
        })
      }
      else
        this.$router.push({
          path:'/policy/info',
          query:{
            policy_id:policy_id,
            type:this.type,
            status,//1draft
          }
        })
    },
    handlerChange(){
      this.pageConfig.currentPage = 1
      this.getList()
    },
    handlerSearch(search_type){
      this.pageConfig.currentPage = 1
      // let params = {
      //   search_content:this.search_content,
      this.setSearch(null)
      this.search_type=search_type
      this.content=this[`search_content_${search_type}`]
      this.search_content=this[`search_content_${search_type}`]
      // this[`search_content_${search_type}`] = val
      // }
      // this.setSearch(params)
      let arr = [1,2,3,4,5,6,7]
      arr.filter(item=>{
        if(item!=search_type){
          this[`search_content_${item}`] =''
        }
      })
      this.getList()
    },
    getList(){
      if(this.isShowTicket)
        this.network().getTicketList()
      else
        this.network().getPolicyList()
    },
    handlerReferrer(id){
      this.$router.push('/member/referral/statement/'+id)
    },
    currentChange(page) {
      this.pageConfig.currentPage = page
      this.getList()
    },
    handleSizeChange(size){
      this.pageConfig.size =size
      this.getList()
    },
    network() {
      return {
        getPolicyList: async () => {

          let dataParams = {
            effective_date_start: this.date? this.date[0]: '',
            effective_date_end: this.date? this.date[1]: '',
            type: this.typeName,
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
            policy_status:this.policy_status,
            // ...this.search,
            search_type:this.search_type,
            search_content:this.search_content,
            company_id:this.company_id,
            product_id:this.product_id,
            created_at_start:this.createdDate?this.createdDate[0]: '',
            created_at_end:this.createdDate?this.createdDate[1]: '',
            expiry_date_start:this.expiryDate?this.expiryDate[0]: '',
            expiry_date_end:this.expiryDate?this.expiryDate[1]: '',
            issue_date_start:this.issueDate?this.issueDate[0]: '',
            issue_date_end:this.issueDate?this.issueDate[1]: '',
            serial_number:this.serial_number,
            created_by:this.created_by,
            policy_type:this.policy_type,
            status:this.type==2?this.policy_status:this.status,
          }
          if(this.type==1){
            dataParams.all_policy_type = Number(this.all_policy_type)
          }
          if(this.type==2){
            delete dataParams.policy_status
          }
          const { data } = await getPolicyList(dataParams)
          let {meta} =data
          this.tableData = data.data
          this.pageConfig.total = meta.total
        },
        getTicketList: async () => {

          let dataParams = {
            effective_date_start: this.date? this.date[0]: '',
            effective_date_end: this.date? this.date[1]: '',
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
            policy_status:this.policy_status,
            // ...this.search,
            search_type:this.search_type,
            search_content:this.content,
            company_id:this.company_id,
            product_id:this.product_id,
            created_at_start:this.createdDate?this.createdDate[0]: '',
            created_at_end:this.createdDate?this.createdDate[1]: '',
            expiry_date_start:this.expiryDate?this.expiryDate[0]: '',
            expiry_date_end:this.expiryDate?this.expiryDate[1]: '',
            issue_date_start:this.issueDate?this.issueDate[0]: '',
            issue_date_end:this.issueDate?this.issueDate[1]: '',
            serial_number:this.serial_number,
            created_by:this.created_by,
            status:this.type==2?this.policy_status:this.status,
          }
          if(this.type==1){
            dataParams.all_policy_type = Number(this.all_policy_type)
          }
          if(this.type==2){
            delete dataParams.policy_status
          }
          const { data } = await getTicketList(dataParams)
          let {meta} =data
          this.tableData = data.data
          this.pageConfig.total = meta.total
        },
        getPolicyListExport: async () => {

          let dataParams = {
            effective_date_start: this.date? this.date[0]: '',
            effective_date_end: this.date? this.date[1]: '',
            type: this.typeName,
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
            policy_status:this.policy_status,
            // ...this.search,
            search_type:this.search_type,
            search_content:this.content,
            company_id:this.company_id,
            product_id:this.product_id,
            created_at_start:this.createdDate?this.createdDate[0]: '',
            created_at_end:this.createdDate?this.createdDate[1]: '',
            expiry_date_start:this.expiryDate?this.expiryDate[0]: '',
            expiry_date_end:this.expiryDate?this.expiryDate[1]: '',
            issue_date_start:this.issueDate?this.issueDate[0]: '',
            issue_date_end:this.issueDate?this.issueDate[1]: '',
            serial_number:this.serial_number,
            created_by:this.created_by,
            policy_type:this.policy_type,
          }
          if(this.type==1){
            dataParams.all_policy_type = Number(this.all_policy_type)
          }
          const data = await getPolicyListExport(dataParams)
          downloadFile(data)
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/policyList';
  .margin-bottom-span{
    margin-bottom: 10px;
    display: block;
  }
.flex-logo{
  align-items: center;
  .company-logo{
    width: 30px;
    height: 20px;
    display: flex;
    img{
      width: 60%;
      display: block;
    }
  }
}
.header-title{
  font-size: 36px;
  color: #000;
  margin-bottom: 30px;
  margin-top: 10px;
}
::v-deep .el-tabs__header{
margin: 0 0 30px!important;
}
.text-blold{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #00072B;
}
.flex-content{
  margin-bottom: 20px;
  .title-tip{
    font-size: 16px;
    font-weight: 600;
    .flex-cloumn:first-child{
      color: #000;
    }
    .flex-cloumn{
      margin-right: 30px;
      display: flex;
      flex-direction: column;
      color: #4A5568;
      justify-content: space-between;
      .number{
        font-size: 28px;
        color: #718096;
      }
    }
  }
  .flex-button{
    align-self: flex-end;
  }
}
/deep/.selection_none {
  .cell{
    display: none;
  }
}
</style>
