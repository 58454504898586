import {mapActions, mapState} from 'vuex'
export default {
  created() {
  },
  computed:{
    ...mapState('common',['listFilterParams'])
  },
  watch:{
    listFilterParams:{
      immediate:true,
      handler(val){
        let tableFilter = val[this.filterTableKey]||{}
        Object.keys(tableFilter).some(key=>{
          if(!(this.filterTableKey=='policy'&&key=='all_policy_type'))
            this[key] = tableFilter[key]
          else this.all_policy_type = tableFilter.all_policy_type
        })
      }
    }
  },
  methods: {
    ...mapActions('common', [ 'setListFilter']),
    resetFilter(){
      let keyArr =Object.keys(this.filterData)
      keyArr.some(key=>{
        if(!(this.filterTableKey=='policy'&&key=='all_policy_type'))
          this[key] = this.filterData[key]
        else {
          this.all_policy_type = this.filterData.all_policy_type
        }
      })
      if(keyArr.includes('typeName')&&this.filterTableKey!='ticket'){
        this.initTypeName()
      }
      this.setListFilter({[this.filterTableKey]:this.filterData})
      this.handlerChange()
    },
    updateFilter(){
      Object.keys(this.filterData).some(key=>{
        this.filterData[key] = this[key]
      })
      this.setListFilter({[this.filterTableKey]:this.filterData})
    }
  }
}
