<template>
  <section
    ref="page"
    class="page-container">

    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <h2
        v-if="showTitle"
        class="title-page">
        Motor Insurance - {{ getOptionName('carClassOptions',carTypeName) }}
      </h2>
      <h2
        v-if="isRewTitle"
        class="title-page">
        Renew Policy
      </h2>
      <h2
        v-if="isEdit&&is_save&&!pathIsEndorsment"
        class="title-page">
        Edit Policy
      </h2>
      <claim-black-list-tip
        v-if="clientBlackData.blacklist||clientBlackData.claimed||clientBlackData.vip"
        :client-black-data="clientBlackData"></claim-black-list-tip>
      <section class="margin-right">
        <policy-detail
          ref="policy-detail"
          :form="formData"
          :formDataLock="formDataLock"
          :effectDate.sync="formData.effective_date"
          :policyType.sync="formData.policy_type"
          :renew_on_hold.sync="renew_on_hold"
          @policyData="getChangeFormData"
          @refresh="handleRefresh"
          @getFormData="getSubFormData"></policy-detail>
        <!--        <div class="info-box">-->
        <!--          <v-title title="Underwriting"></v-title>-->
        <!--          <underwriting-->
        <!--            ref="underwriting"-->
        <!--            :form="formData"-->
        <!--            :formDataLock="formDataLock"-->
        <!--            @getFormData="getSubFormData"></underwriting>-->
        <!--        </div>-->
        <section v-show="!isNowRenewOnHold||isPreRenewOnHold">
          <div class="info-box">
            <v-title title="Policyholder Details"></v-title>
            <policy-holder
              ref="policy-holder"
              :form="formData"
              :formDataLock="formDataLock"
              :carType="formData.class_id"
              :client-type="clientType"
              @getFormData="getSubFormData"
              @one-driver="oneDriver"
            />
          </div>
          <div
            v-if="formData.class_id!==1004||formData.class_id===1004&&cover_type==4"
            class="info-box">
            <driver-detail-form
              ref="driverDetail"
              :form="formData"
              :formDataLock="formDataLock"
              :driverDocument.sync="driverListDocument"
              :clientType="formData.client_type"
              @getFormData="getSubFormData"
            ></driver-detail-form>
          </div>
          <!--            motor trade 不显示-->
          <div v-if="formData.class_id!==1004">
            <div class="info-box">
              <v-title title="Vehicle Details"></v-title>
              <vehicle-detail-form
                ref="vehicleDetail"
                :formDataLock="formDataLock"
                :form="formData"
                :carType="formData.class_id"
                @getFormData="getSubFormData"
              ></vehicle-detail-form>
            </div>

          </div>
          <div class="info-box">
            <v-title title="Insurer"></v-title>
            <inusurance
              ref="insurer"
              :formDataLock="formDataLock"
              :form="formData"
              :carType="formData.class_id"
              @getFormData="getSubFormData"
              @policyData="getChangeFormData"
            ></inusurance>
          </div>
          <div class="info-box">
            <v-title title="Coverage"></v-title>
            <coverage
              ref="coverage"
              :formDataLock="formDataLock"
              :form="formData"
              :carType="formData.class_id"
              @coverageData="getChangeFormData"
              @getFormData="getSubFormData"
            ></coverage>
          </div>
          <div class="info-box">
            <v-title title="Excess"></v-title>
            <excess
              ref="excess"
              :formDataLock="formDataLock"
              :form="formData.excess"
              :carType="formData.class_id"
              :coverType="cover_type"
              @getFormData="getSubFormData"
            ></excess>
          </div>
        </section>
        <div
          v-if="isRenew"
          class="info-box">
          <v-title
            title="Remarks"
            litter="(Display on Renewal Request)"></v-title>
          <el-form-item prop="renew_remark">
            <v-input
              v-model="formData.renew_remark"
              type="textarea"
              :is-upper="false"
              :rows="4"></v-input>
          </el-form-item>
        </div>
        <section v-show="!isNowRenewOnHold||isPreRenewOnHold">
          <div
            class="info-box">
            <v-title
              title="Remarks"
              litter="(Display on Receipt)"></v-title>
            <el-form-item prop="remark">
              <v-input
                v-model="formData.remark"
                type="textarea"
                :is-upper="false"
                :rows="4"></v-input>
            </el-form-item>
          </div>
          <div class="info-box">
            <v-title title="Documents"></v-title>
            <document
              ref="document"
              :form="formData"
              :formDataLock="formDataLock"
              :driver-document="driverListDocument"
              :classType="formData.class_id"
              :clientType="formData.client_type"
              @getFormData="getSubFormData"></document>
          </div>
          <div
            v-if="(isRenew||isQueryRenew)&&!isEndorsement"
            class="info-box">
            <v-title title="Supplementary Document Request"></v-title>
            <supplementary-document
              ref="supplementary-document"
              :form="formData"
              :formDataLock="formDataLock"
              @getFormData="getSubFormData"
            ></supplementary-document>
          </div>
          <div
            class="info-box">
            <v-title title="Signature"></v-title>
            <sign-form  :form="formData"></sign-form>
          </div>
          <div
            ref="dom"
            class="info-box">
            <referral
              ref="referral"
              :formDataLock="formDataLock"
              :form="formData.referral"
              @getFormData="getSubFormData"></referral>
          </div>
        </section>
      </section>

    </el-form>
  </section>
</template>

<script>
import policyDetail from '@/views/components/policyDetail/policyDetail'
import PolicyHolder from '@/views/components/policyDetail/policyHolderForm'
import vehicleDetailForm from '@/views/components/motor/vehicleDetailForm'
import excess from '@/views/components/motor/excess'
import driverDetailForm from '@/views/components/motor/driverDetailForm'
import document from '@/views/components/motor/document'
import coverage from '@/views/components/motor/coverage'
import signForm from '@/views/components/motor/sign-form'
import referral from '@/views/components/motor/referral'
import inusurance from '@/views/components/motor/insurance'
import MixinRules from '@/mixins/rules'
import MixinOptions from '@/mixins/options'
import MixinSame from '@/mixins/same'
import {mapActions, mapState} from 'vuex'
import ClaimBlackListTip from '@/views/components/claimBlackListTip'
import supplementaryDocument from '@/views/components/motor/supplementary-document'

export default {
  name: 'motor',
  components: {
    ClaimBlackListTip,
    // Underwriting,
    policyDetail,
    PolicyHolder,
    vehicleDetailForm,
    excess,
    driverDetailForm,
    document,
    coverage,
    referral,
    inusurance,
    signForm,
    supplementaryDocument
  },
  mixins: [MixinRules,MixinOptions,MixinSame],
  data() {
    return {
      formDataLock: 0, // 表單鎖，防止外層修改數據覆蓋了組件裏的表單數據
      ncdShow:0,
      order_no:'',
      carTypeName: 1001,
      clientType: 1,
      isNext: false,
      cover_type: 1,
      renew_on_hold: 0,
      insurer:0,
      policyOneDriver:false,
      driverListDocument:{},
      subForm: {
        'policy-detail':false,
        // underwriting:false,
        insurer:false,
        'policy-holder':false,
        'coverage': false,
        excess:false,
        vehicleDetail:false,
        driverDetail: false,
        'document':false,
        'supplementary-document':false,
        'referral':false
      },
      obj:{},
      formData: {
        draft:false,
        product_id:10,
        class_id:1003,
        confirm_underwriting:'',
        underwriting_content:'',
        coverage: {
          cover_type: 1, //1 third party 2 comprehensive
          hire_purchase_owner: 1, //1 yes,no-2
          hire_purchase_owner_id: '',
          estimated_vehicle_value: 0,
          policy_send_to_hire_purchase_owner_date: '',
        },
        remark: '',
        renew_remark : '',
        policy_type:200
      }
    }
  },

  computed: {
    ...mapState('quotation', ['quoteInformationForm']),
    sign_pic(){
      let {upload_document={},signature={}} = this.quoteInformationForm
      let sign_data = signature||{}
      return sign_data
    },
    isEdit(){
      if(this.quoteInformationForm)
      {
        let {is_edit=''} = this.quoteInformationForm
        return is_edit==1?true:false
      }return  false
    },
    is_save(){
      if(this.quoteInformationForm)
      {
        let {is_save=false} = this.quoteInformationForm
        return is_save
      }return  false
    },
    isEndorsement(){
      let {isEndorsement} = this.$route.query||{}
      if(isEndorsement){
        return  true
      }
      if(this.quoteInformationForm)
      {
        let {policy_type} = this.quoteInformationForm
        return policy_type==203?true:false
      }return  false
    },
    showTitle(){
      let {policy_type=200} = this.quoteInformationForm||{}
      return (!this.isEdit||!this.is_save)&&!this.isEndorsement&&policy_type==200
    },
    isRewTitle(){
      let {policy_type=200} = this.quoteInformationForm||{}
      return (!this.isEdit||!this.is_save)&&!this.isEndorsement&&policy_type==201
    },
    pathIsEndorsment(){
      let path = this.$route.path
      return path.indexOf('/quotation/endorsement')==0
    },
    isQueryRenew(){
      return this.$route.query.isRenew =='true'
    },
    isRenew(){
      return   this.formData.policy_type ==201||this.$route.query.isRenew == 'true'
    },
    draft(){
      return this.$route.query.isDraft=='true'
    },
    isPreRenewOnHold(){
      let {renew_on_hold} = this.quoteInformationForm||{}
      return renew_on_hold==1&&!this.draft
    },
    isNowRenewOnHold(){
      return this.renew_on_hold==1&&this.isRenew
    },
  },
  watch:{
    quoteInformationForm:{
      immediate:true,
      handler(val){
        let {client_type = 1, company_id = 0, coverage = {},class_id,remark} = val||{}
        if(val){
          this.formData = {...this.formData,...val}
          let {cover_type} = coverage
          this.carTypeName = class_id||1001
          this.clientType = client_type
          this.insurer = company_id
          this.cover_type = cover_type
        }
        this.formData.remark = remark||this.remarkInit
      }
    },
    isNowRenewOnHold:{
      immediate:true,
      handler(val){
        this.$emit('update:renew_on_hold',val)
      }
    }
  },
  created() {
    // if(this.quoteInformationForm){
    //   this.formData = this.quoteInformationForm
    //   let {client_type = 1, company_id = 0, coverage = {},class_id} = this.quoteInformationForm
    //   let {cover_type} = coverage
    //   this.carTypeName = class_id||1001
    //   this.clientType = client_type
    //   this.insurer = company_id
    //   this.cover_type = cover_type
    // }
  },
  mounted() {
    this.$nextTick(()=>{

      if(this.$route.query.isDoucment=='true')
        // this.$refs.page.scrollIntoView(false)
        this.$refs.page.scrollIntoView({behavior: 'smooth', block: 'end',})
    })
  },
  methods: {
    ...mapActions('quotation', ['setQuoteInformationForm','setNcd']),
    handleRefresh(){
      this.$emit('refresh',true)
    },
    getChangeFormData(data) {
      this.formData = Object.assign(this.formData, data)
      let {client_type = 1, company_id = 1, coverage = {},class_id} = this.formData
      let {cover_type } = coverage
      this.carTypeName = class_id||1001
      this.clientType = client_type?client_type:this.clientType
      this.insurer = company_id
      this.cover_type = cover_type?cover_type:this.cover_type
      this.$emit('handlerCompanyId',company_id)
      this.$emit('handlerClassId',class_id)

    },
    getSubFormData(form, data) {
      this.subForm[form] = true
      this.obj[form] = true
      this.formData = Object.assign(this.formData, data)
    },
    submitForm(formName){
      this.obj = JSON.parse(JSON.stringify(this.subForm))
      let {class_id,coverage} = this.formData
      let {cover_type } = coverage
      if(class_id===1004)
        delete this.obj.vehicleDetail
      if(class_id===1004&&cover_type!==4)
        delete this.obj.driverDetail
      // 獲取表單組件數據
      if(!this.isRenew&&!this.isQueryRenew){
        delete this.obj['supplementary-document']
      }
      Object.keys(this.obj).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key,this.is_draft)
      })
      if (Object.values(this.obj).some(item => item === false)) {
        return
      }
      this.$refs.form.validate((valid, invalidFields) => {
        if (valid) {
          let {is_delivery_purpose} = this.formData
          this.formData.vehicle_detail.is_delivery_purpose= is_delivery_purpose
          this.$emit('getFormData', formName, this.formData)
        }else{

        }
      })
    },
    handlerValidate() {
      this.obj = JSON.parse(JSON.stringify(this.subForm))
      let {class_id,coverage} = this.formData
      let {cover_type } = coverage
      if(class_id===1004)
        delete this.obj.vehicleDetail
      if(class_id===1004&&cover_type!==4)
        delete this.obj.driverDetail
      // 獲取表單組件數據
      if(!this.isRenew&&!this.isQueryRenew){
        delete this.obj['supplementary-document']
      }
      Object.keys(this.obj).some(key => {
        this.obj[key] = false
        this.$refs[key].submitForm(key,this.is_draft)
      })
      if (Object.values(this.obj).some(item => item === false)) {
        return
      }
      this.$refs.form.validate((valid, rules) => {
        if (valid) {
          let {is_delivery_purpose} = this.formData
          this.formData.vehicle_detail.is_delivery_purpose= is_delivery_purpose
          this.$emit('submitData',this.formData,this.is_draft)
        }else{
          this.scrollToValidDom(rules)
        }
      })
    },
    // 投保人資料關聯幾名司機
    oneDriver(boolean,data) {
      this.policyOneDriver = boolean
      // this.$nextTick(() => {
      this.formData = Object.assign(this.formData, data)
      setTimeout(() => {
        // if (boolean)
        this.$refs['driverDetail'].handlerPolicyDriverInfo(data)
      }, 600)
      // })
    },
    scrollToDom(dom) {
      try {
        if(dom) {
          const { top } = dom.getBoundingClientRect()
          const scrollY = window.scrollY
          const { offsetHeight } = dom
          const scrollTopResult = Number(top) + Number(scrollY) - offsetHeight - 150
          this.scrollTo(Number(scrollTopResult))
        }
        else {
          this.scrollTo(0)
        }
      } catch (e) {
        console.log(e)
      }
    },
    scrollTo(top) {
      try {
        const scrollContainer = document.getElementsByClassName('scroll-content')[0]
        scrollContainer.scrollTo({
          top,
          behavior: 'smooth'
        })

      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page-container {
  position: relative;
  .sign_pic {
    display: flex;
    //justify-content: center;
    background: white;
    border-radius: 8px;
    background: var(--black-white-white, #FFF);
    padding: 20px;
  }
  //padding: 15px;
  .header-title {
    color: #000000;
    font-weight: 700;
    width: 100%;


    span {
      font-size: 16px;
    }

    &.flex {
      padding: 20px 0;
      align-items: center;

      i {
        margin-right: 10px;
      }
    }
  }

  .main-container {
    .eff-box {
      width: calc(50% - 10px);
    }

    .document-wrapper {
      .flex {
        .form-item {
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .drivers-file-container {
        position: relative;
        display: flex;
        flex-wrap: wrap;

        .card {
          position: relative;
          margin-bottom: 15px;
          width: calc(50% - 10px);

          &:nth-child(2n-1) {
            margin-right: 10px;
          }

          &:nth-child(2n) {
            margin-left: 10px;
          }

          .mask {
            z-index: 2;
            position: absolute;
            top: 40px;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(255, 255, 255, 0.7);
          }
        }
      }
    }
  }

  .el-col {
    padding: 0;
  }
}
</style>
