import {deleteOrder} from '@/api/order'
import {unassign} from '@/api/assginee'
import {mapActions} from 'vuex'

export default {
  data(){
    return{
      renew_on_hold: false
    }
  },
  mounted(){
    let {order_no,isDraft} = this.$route.query||{}
    if(order_no)
      this.network().getOrderDetail({order_no})
  },
  computed:{
    isQueryRenew(){
      return this.$route.query.isRenew =='true'
    },
    isNoPayment(){
      let {payment} = this.quoteInformationForm||{}
      return !payment
    },
    isBlackList(){
      let {blacklist=false} = this.clientBlackData||{}
      return blacklist
    },
    showAssginBtn(){
      let {policy_status} = this.quoteInformationForm||{}
      return policy_status == 10
    },
    is_edit(){
      let {order_no,isDraft} = this.$route.query||{}
      if(isDraft=='true'){
        return  1
      }
      if(!this.order_no)
        return  0
      return order_no!==this.order_no?1:0
    }
  },
  methods: {
    ...mapActions('quotation', ['setNcd','setCommissionNet','setInsureNet']),
    ...mapActions('coverage', ['setDomesticPremium','setHomePremium']),
    handleRefresh(isRenew){
      let {order_no,isDraft} = this.$route.query||{}
      if(order_no)
        this.network().getOrderDetail({order_no},isRenew)
    },
    handlerCancel(){
      this.$router.back()
    },
    handlerNext(){
      this.setDomesticPremium(null)
      this.setHomePremium(null)
      this.is_draft = false
      this.handlerValidate()
    },
    handleBack(){
      this.setDomesticPremium(null)
      this.setHomePremium(null)
      this.$router.back()
    },
    handleCancel(){
      this.setDomesticPremium(null)
      this.setHomePremium(null)
      this.$ConfirmBox({
        title: '',
        message: 'Quote will not be auto saved,are you confirmed to leave?'
      }).then(() => {
        this.$router.back()
      })
      this.$emit('cancel',true)
    },
    saveOrderToPage(order_no){
      let {payment,is_continue_renew} = this.quoteInformationForm||{}
      this.isNext = true
      let params = {

      }
      if(is_continue_renew==1){
        params.is_continue_renew = 1
      }
      if(this.is_save){
        this.$router.back()
      }else{
        if(payment&&!this.isQueryRenew){
          this.$router.push({
            path: '/quotation/premium/info',
            query:{
              order_no,
            }
          })
        }else
        {
          this.$router.push({
            name: 'info',
            query:{
              order_no,
              isEndorsement:false,
              ...params
            }
          })
        }
        // this.setQuoteInformationForm(params)

      }
    },
    handlerUnassign(){
      this.$ConfirmBox({
        title: '',
        message: 'Ticket/Quotation will been sent back for reassignment, are you confirm?',
        confirmBtn:'Confirm',
        cancelBtn:'Cancel'
      }).then(() => {
        this.mixinNetwork().unassign()
      })
    },
    handlerDelete(){
      this.$ConfirmBox({
        title: '',
        message: 'Are you sure you want to delete this policy?',
        confirmBtn:'Yes',
        cancelBtn:'No'
      }).then(() => {
        this.mixinNetwork().deleteOrder()
      })
    },
    mixinNetwork(){
      return{
        unassign:async (params)=>{
          params ={
            order_no:this.order_no
          }
          let {data} = await unassign(params)
          this.$router.replace('/ticket/list')
        },
        deleteOrder:async (params)=>{
          params ={
            order_no:this.order_no
          }
          let {data} = await deleteOrder(params)
          this.setQuoteInformationForm(null)
          let {policy_id} =data||{}
          if(policy_id>0)
          {
            this.$router.replace({
              path:'/policy/info',
              query:{
                policy_id
              }
            })
          }else
            this.$router.replace('/policy/draft')
        },
      }
    }
  }
}
