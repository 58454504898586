<template>
  <div class="right ">
    <div class="card">
      <div>
        <div class="flex">
          <!--          <span class="label is-bold">{{isRenew?'Renew':'New'}} Policy</span>-->
          <span class="label is-bold">{{isRenewOnHold?'Continue':policyname}}</span>
        </div>
        <template v-if="isRenewOnHold">
          <v-button
            class="button-blue button-theme-padding-13"
            @click="handlerContinueRenew"
          >
            <font-awesome-icon
              icon="plus"
              class="margin-right"></font-awesome-icon>
            <span>Continue Renew</span>
          </v-button>
        </template>
        <template v-else>
          <v-button
            class="button-blue button-theme-padding-13"
            :class="!isAble||isPayOnAccount?'disable-class':''"
            :disabled="!isAble||isPayOnAccount"
            @click="handlerEndorsement"
          >
            <font-awesome-icon
              icon="edit"
              class="margin-right"></font-awesome-icon>

            <span>Endorsement</span>
          </v-button>
          <v-button
            class="button-blue button-theme-padding-13"
            :class="!isAble?'disable-class':''"
            :disabled="!isAble"
            @click="hanlderCancel"
          >
            <font-awesome-icon
              icon="minus-circle"
              class="margin-right"></font-awesome-icon>
            <span>Cancel Policy</span>
          </v-button>
          <v-button
            class="button-blue button-theme-padding-13"
            :class="!isRenew?'disable-class':''"
            :disabled="!isRenew"
            @click="handlerRenew"
          >
            <font-awesome-icon
              icon="plus"
              class="margin-right"></font-awesome-icon>
            <span>Create Renew Quote</span>
          </v-button>
        </template>
      </div>

    </div>
  </div>

</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'policyDailog',
  props:{
    isRenew:{
      type:Boolean,
      default:false
    },
    isPayOnAccount:{
      type:Boolean,
      default:false
    },
    isRenewOnHold:{
      type:Boolean,
      default:false
    },
    isAble:{
      type:Boolean,
      default:true
    },
    policyname:{
      type:String,
      default:'New Policy'
    },

  },
  computed:{
    ...mapState('quotation', ['quoteInformationForm']),
  },
  methods:{
    ...mapActions('quotation', ['setQuoteInformationForm']),
    handlerContinueRenew(){
      this.handlerRenew(true)
    },
    handlerEndorsement(){
      let params = {
        ...this.quoteInformationForm
      }
      params.policy_type=203
      this.setQuoteInformationForm(params)
      this.$router.push({
        path:'/quotation/endorsement',
        query:{
          isEndorsement:true
        }
      })
    },
    hanlderCancel(){
      let params = {
        ...this.quoteInformationForm
      }
      params.policy_type=204
      this.setQuoteInformationForm(params)
      this.$router.push('/quotation/cancel')
    },
    handlerRenew(isContinue=false){
      let params = {
        ...this.quoteInformationForm
      }
      params.policy_type=201
      params.order_status = ''
      let {order_no} = params
      let path = {
        10:'/quotation/create/motor',
        11:'/quotation/create/contractors',
        12:'/quotation/create/travel',
        13:'/quotation/create/home',
        14:'/quotation/create/employee',
        15:'/quotation/create/business',
        16:'/quotation/create/domestic',
        17:'/quotation/create/construction',
        18:'/quotation/create/other',
      }
      this.setQuoteInformationForm(params)
      this.$router.push({
        path:path[params.product_id],
        query:{
          isDraft:false,
          isRenew:true,
          order_no,
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.right {
  //margin-top: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
.card {
  background: white;
  border-radius: 5px;
  padding: 15px;
}
  .button-blue{
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
  .is-bold{
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    margin-bottom: 20px;
  }
}
.margin-right{
  margin-right: 5px;
}
</style>
